import React from 'react'
import { IconClose, IconInfo } from 'atoms/Icons'
import { Wrapper, MiniWrapper } from './styled'

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: false,
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    const { closed } = this.state
    this.setState({ closed: !closed })
  }

  render() {
    const { closed } = this.state
    if (closed)
      return (
        <MiniWrapper onClick={this.handleClose}>
          <IconInfo height="32px" width="32px" fill="lightgrey" />
        </MiniWrapper>
      )
    return (
      <Wrapper>
        <div>
          NEW UPDATE: Backend has been upgraded for performance. Enjoy a faster
          SatoshiDICE experience!
        </div>
        <div onClick={this.handleClose} style={{ cursor: 'pointer' }}>
          <IconClose />
        </div>
      </Wrapper>
    )
  }
}
export default Notification

import React from 'react'
import { Link } from 'gatsby'
import { withTheme } from 'styled-components'
import { injectIntl } from 'react-intl'
import HelpButton from 'components/HelpButton'
import Languages from './Languages'

import { IconVolumeOn, IconVolumeOff, IconMenu, IconMusicOn } from 'atoms/Icons'
import {
  Wrapper,
  MenuContainer,
  MainMenu,
  MobileMenu,
  MenuItems,
  StyledLink,
  Logo,
  SoundLink,
  SoundButton,
  TopMenu,
  OtherGames,
} from './styled'
import { pages } from 'data/settings'

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuVisible: false,
      showMenu: false,
    }

    this.handleMenuShow = this.handleMenuShow.bind(this)
  }

  componentDidMount() {
    const { sound } = this.props
    this.setState({
      sound,
    })
  }

  handleMenuShow() {
    this.setState({
      menuVisible: !this.state.menuVisible,
    })
  }

  handleSoundToggle() {
    const { soundFn } = this.props
    this.setState({
      sound: !this.state.sound,
    })
    soundFn()
  }

  render() {
    const { intl, menuFor, musicStatus } = this.props
    const { messages } = intl
    const { menuVisible, sound } = this.state

    const SoundIcon = sound ? <IconVolumeOn /> : <IconVolumeOff />

    const MusicIcon = musicStatus ? <IconMusicOn /> : <IconVolumeOff />
    return (
      <Wrapper>
        <MenuContainer>
          <TopMenu>
            <Link
              to={`${pages[menuFor].url}/${
                intl.locale === 'en' ? '' : intl.locale
              }`}
            >
              <Logo src={pages[menuFor].logo} />
            </Link>

            <OtherGames top>
              {Object.keys(pages).map(item => {
                const details = pages[item]
                if (item === menuFor) return null
                if (details.title === 'SatoshiSTACK')
                  return (
                    <a
                      href={`${details.url}`}
                      target="_blank"
                      key={details.title}
                      rel="noopener noreferrer"
                    >
                      <Logo src={details.logo} style={{ maxHeight: 40 }} />
                    </a>
                  )
                return (
                  <Link to={`${details.url}`} key={details.title}>
                    <Logo src={details.logo} style={{ maxHeight: 40 }} />
                  </Link>
                )
              })}
            </OtherGames>
          </TopMenu>
          <MobileMenu onClick={this.handleMenuShow}>
            <IconMenu size={36} />
          </MobileMenu>
          <MainMenu show={menuVisible}>
            <HelpButton />

            <MenuItems show={menuVisible}>
              <OtherGames>
                {Object.keys(pages).map(item => {
                  const details = pages[item]
                  if (item === menuFor) return null
                  if (details.title === 'SatoshiSTACK')
                    return (
                      <a
                        href={`${details.url}`}
                        target="_blank"
                        key={details.title}
                        rel="noopener noreferrer"
                      >
                        <Logo src={details.logo} style={{ maxHeight: 40 }} />
                      </a>
                    )
                  return (
                    <Link to={`${details.url}`} key={details.title}>
                      <Logo src={details.logo} style={{ maxHeight: 40 }} />
                    </Link>
                  )
                })}
              </OtherGames>
              {pages[menuFor].subPages.map(item => (
                <StyledLink
                  key={item.title}
                  to={`${intl.locale === 'en' ? '' : `/${intl.locale}`}/${
                    item.url
                  }`}
                >
                  {item.title}
                </StyledLink>
              ))}
              <Languages />
              <SoundButton
                onClick={this.handleSoundToggle.bind(this)}
                show={menuVisible}
              >
                <SoundLink as="p">
                  {messages.Menu.sound}{' '}
                  {sound
                    ? messages.Menu.soundstatus_on
                    : messages.Menu.soundstatus_off}
                </SoundLink>
                {SoundIcon}
              </SoundButton>
              {this.props.musicFn && (
                <SoundButton onClick={this.props.musicFn} show={menuVisible}>
                  <SoundLink as="p">{messages.Menu.music}</SoundLink>
                  {MusicIcon}
                </SoundButton>
              )}
            </MenuItems>
          </MainMenu>
        </MenuContainer>
      </Wrapper>
    )
  }
}

export default injectIntl(withTheme(Menu))

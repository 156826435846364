import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'

import { ThemeProvider } from 'styled-components'

import { mainTheme } from 'styles/theme'

// Locale data
import enData from 'react-intl/locale-data/en'
import jaData from 'react-intl/locale-data/ja'
import zhData from 'react-intl/locale-data/zh'
import koData from 'react-intl/locale-data/ko'

// Messages
import en from 'i18n/en.json'
import ja from 'i18n/ja.json'
import ko from 'i18n/ko.json'
import zh from 'i18n/zh.json'

import { pages } from 'data/settings'

const messages = { en, ja, ko, zh }

addLocaleData([...enData, ...jaData, ...koData, ...zhData])

class Layout extends React.Component {
  render() {
    const { children, locale, addWinlistFn, layout } = this.props
    const SubLayout = pages[layout].layout
    return (
      <IntlProvider locale={locale} messages={messages[locale]}>
        <ThemeProvider theme={mainTheme}>
          <SubLayout locale={locale} addWinlistFn={addWinlistFn}>
            {children}
          </SubLayout>
        </ThemeProvider>
      </IntlProvider>
    )
  }
}

export default Layout

module.exports = {
    en: {
        path: 'en',
        locale: 'English',
        default: true
    },
    ja: {
        path: 'ja',
        locale: '日本語'
    },
    ko: {
        path: 'ko',
        locale: '한국어'
    },
    zh: {
        path: 'zh',
        locale: '中文'
    }

}
import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'
import numeral from 'numeral'

import { getTxids, getSelectedValue } from 'data/meta/selectors'
import { getSelectedGame } from 'data/gamerules/selectors'
import { addTransactionId } from 'data/meta/actions'
import { getPrices } from 'data/prices/selectors'
import { getExplorerUrl } from 'data/api'

import { formatBCHValue } from 'styles/utils'
import { IconCancel, IconSad, IconHappy } from 'atoms/Icons'

import {
  Wrapper,
  StyledBadger,
  ButtonContainer,
  GameStats,
  StatItem,
  Value,
  CloseButton,
  Title,
} from './styled'

class ResultModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = { show: false, timer: null }

    this.handleHideModal = this.handleHideModal.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { txids, latestGame } = this.props
    const { show, timer } = this.state

    if (show && timer !== null && latestGame.id !== prevProps.latestGame.id) {
      clearTimeout(timer)
      this.setState({
        timer: setTimeout(() => {
          this.setState({
            show: false,
            timer: null,
          })
        }, 10 * 1000),
      })
    }

    if (
      !show &&
      txids.includes(latestGame['depositTxHash']) &&
      latestGame.id !== prevProps.latestGame.id
    ) {
      this.setState({
        show: true,
        timer: setTimeout(() => {
          this.setState({
            show: false,
            timer: null,
          })
        }, 10 * 1000),
      })
    }
  }

  handleHideModal() {
    this.setState({ show: false })
  }

  shrinkTxHash = tx => {
    if (tx === undefined) return 'N/A'
    return tx.substring(0, Math.min(tx.length, 10)) + '...'
  }

  render() {
    const {
      intl: { messages },
      latestGame,
      txids,
      selectedGame,
      selectedValue,
      theme,
      addTransactionId,
      priceUSD,
    } = this.props

    const { show } = this.state

    if (typeof txids === 'undefined') return null
    if (typeof selectedGame === 'undefined') return null

    const resultMessage = latestGame['win']
      ? messages.Winlist.playerWon
      : messages.Winlist.playerLost

    const resultIcon = latestGame['win'] ? (
      <IconHappy height={32} width={32} />
    ) : (
      <IconSad height={32} width={32} />
    )

    const halfBet =
      selectedValue / 2 < selectedGame.min
        ? selectedGame.min
        : selectedValue / 2
    const doubleBet =
      selectedValue * 2 > selectedGame.max
        ? selectedGame.max
        : selectedValue * 2

    return (
      <Wrapper show={show}>
        <CloseButton onClick={this.handleHideModal}>
          <IconCancel />
        </CloseButton>
        <Title>
          <h2>{resultMessage}</h2>
          <div style={{ marginLeft: 16 }}>{resultIcon}</div>
        </Title>
        <GameStats>
          <StatItem>
            {messages.Winlist.id}
            <Value>{latestGame.id}</Value>
          </StatItem>
          <StatItem>
            {messages.Winlist.tx}
            <Value>
              <a
                color={theme.tertiary}
                href={getExplorerUrl(latestGame.depositTxHash)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.shrinkTxHash(latestGame.depositTxHash)}
              </a>
            </Value>
          </StatItem>
          <StatItem>
            {messages.Winlist.bet}
            <Value>{latestGame.bet}</Value>
          </StatItem>
          <StatItem>
            {messages.Winlist.roll}
            <Value>{latestGame.roll === -1 ? 'N/A' : latestGame.roll}</Value>
          </StatItem>
          <StatItem>
            {messages.Winlist.betamount}
            <Value win={latestGame.win} lose={!latestGame.win}>
              {formatBCHValue(latestGame.betAmount)} {messages.bch}
            </Value>
            {priceUSD && (
              <Value small>
                (${(latestGame.betAmount * priceUSD).toFixed(2)} USD)
              </Value>
            )}
          </StatItem>
          <StatItem>
            {messages.Winlist.payoutamount}
            <Value win={latestGame.win} lose={!latestGame.win}>
              {formatBCHValue(latestGame.payout)} {messages.bch}
            </Value>
            {priceUSD && (
              <Value small>
                (
                {numeral((latestGame.payout * priceUSD).toFixed(2)).format(
                  '$0,0.00'
                )}{' '}
                USD)
              </Value>
            )}
          </StatItem>
        </GameStats>
        <h3>{messages.Winlist.playagain}</h3>
        <ButtonContainer
          items={
            halfBet === selectedValue || doubleBet === selectedValue ? 2 : 3
          }
        >
          {halfBet !== selectedValue && (
            <StyledBadger
              to={selectedGame.cashaddr}
              amount={halfBet}
              successFn={res => {
                addTransactionId(res)
              }}
            >
              {messages.Winlist['bet-half']}
              <br />({halfBet} {messages.bch})
            </StyledBadger>
          )}
          <StyledBadger
            to={selectedGame.cashaddr}
            amount={selectedValue}
            successFn={res => {
              addTransactionId(res)
            }}
          >
            {messages.Winlist['bet-same']}
            <br />({selectedValue} {messages.bch})
          </StyledBadger>
          {doubleBet !== selectedValue && (
            <StyledBadger
              to={selectedGame.cashaddr}
              amount={doubleBet}
              successFn={res => {
                addTransactionId(res)
              }}
            >
              {messages.Winlist['bet-double']}
              <br />({doubleBet} {messages.bch})
            </StyledBadger>
          )}
        </ButtonContainer>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const txids = getTxids(state)
  const selectedGame = getSelectedGame(state)
  const selectedValue = getSelectedValue(state)
  const { USD } = getPrices(state)
  return { txids, selectedGame, selectedValue, priceUSD: USD }
}

const mapDispatchToProps = {
  addTransactionId,
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(injectIntl(ResultModal)))

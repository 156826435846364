import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import numeral from 'numeral'
import H1 from 'atoms/H1'
import Text from 'atoms/Text'
import Slider from 'atoms/Slider'
import detectmob from 'helpers/deviceDetect'

import BetChances from 'components/BetChances'

import {
  Wrapper,
  InputContainer,
  Input,
  SliderContainer,
  ButtonContainer,
  Button,
  Switch,
  InputCheck,
  SwitchSlider,
} from './styled'

import { setSelectedValue } from 'data/meta/actions'

import { getSelectedGame } from 'data/gamerules/selectors'
import { getSelectedValue } from 'data/meta/selectors'
import { getPrices } from 'data/prices/selectors'
import { addTransactionId } from 'data/meta/actions'

const DEFAULT_USD_VALUE = 5

class ValueSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      currency: 'BCH',
      inputValue: 0,
    }

    this.handleChangeCurrency = this.handleChangeCurrency.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleInputBlur = this.handleInputBlur.bind(this)
    this.handleSlide = this.handleSlide.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.logData = this.logData.bind(this)
    this.handleButtonClick = this.handleButtonClick.bind(this)
    this.handleSlideChange = this.handleSlideChange.bind(this)
    this.handleOddsChange = this.handleOddsChange.bind(this)
    this.handleBadger = this.handleBadger.bind(this)
  }

  componentDidMount() {
    const { selectedValue } = this.props

    this.setState({
      inputValue: selectedValue,
    })

    const position = this.logPosition(selectedValue * 100000000)
    this.NoUiSlider.slider.set([position])
  }

  handleOddsChange(prevMax) {
    const { selectedValue, usdPrice, betInfoData } = this.props
    const { max, min } = betInfoData
    const { currency } = this.state
    let betAmount = !selectedValue
      ? Number((DEFAULT_USD_VALUE / usdPrice).toFixed(8))
      : Number(selectedValue)

    if (betAmount === Number(prevMax)) betAmount = Number(max)
    if (betAmount > Number(max)) betAmount = Number(max)
    if (betAmount < Number(min)) betAmount = Number(min)

    const value =
      currency === 'BCH' ? betAmount : Number((betAmount * usdPrice).toFixed(2))

    this.NoUiSlider.slider.set([this.logPosition(betAmount * 100000000)])
    this.handleValueChange(value, true)
  }

  handleChangeCurrency(el) {
    const { usdPrice, selectedValue } = this.props
    const currency = this.state.currency === 'BCH' ? 'BTC' : 'BCH'
    const valueBCH = Number(selectedValue).toFixed(8)
    const valueFiat = (selectedValue * usdPrice).toFixed(2)
    const inputValue = currency === 'BCH' ? valueBCH : valueFiat

    this.setState({
      currency,
      inputValue,
    })
  }

  handleValueChange(value, isFinal) {
    const { usdPrice, betInfoData, setSelectedValue } = this.props
    const { min, max } = betInfoData
    const { currency } = this.state
    const amount = Number(value)

    let selectedAmount =
      currency === 'BCH' ? amount : (amount / usdPrice).toFixed(8)

    let position = this.logPosition(selectedAmount * 100000000)

    if (selectedAmount > max) {
      isFinal && setSelectedValue(max)
      selectedAmount = max
      position = 100
    } else if (selectedAmount < min) {
      isFinal && setSelectedValue(min)
      selectedAmount = min
      position = 1
    } else {
      isFinal && setSelectedValue(selectedAmount)
    }

    isFinal && this.NoUiSlider.slider.set([position])

    setSelectedValue(selectedAmount)
    this.setState({
      inputValue: value,
    })
  }

  handleBadger(to, satoshis) {
    const { addTransactionId } = this.props
    let web4bch = window.web4bch
    if (typeof web4bch !== 'undefined') {
      web4bch = new window.Web4Bch(window.web4bch.currentProvider)

      const txParams = {
        to,
        from: web4bch.bch.defaultAccount,
        value: satoshis,
      }
      web4bch.bch.sendTransaction(txParams, (err, res) => {
        addTransactionId(res)
      })
    } else {
      const isMobile = detectmob()
      if (isMobile) {
        window.open(`${to}?amount=${satoshis / 100000000}`)
      }
    }
  }

  handleInputChange(el) {
    this.handleValueChange(el.target.value, true)
  }

  handleInputBlur(el) {
    const { betInfoData, usdPrice } = this.props
    const { currency } = this.state
    const { min, max } = betInfoData

    let value =
      currency === 'BCH'
        ? el.target.value
        : (el.target.value / usdPrice).toFixed(8)

    if (value > max) {
      value = max
    } else if (value < min) {
      value = min
    }

    value = currency === 'BCH' ? value : (value * usdPrice).toFixed(2)

    this.handleValueChange(value, true)
  }

  handleSlide(value) {
    const { currency } = this.state
    const { usdPrice } = this.props
    const amount = this.logSlider(Number(value[0]))
    const sats = (amount / 100000000).toFixed(8)
    const betAmount = currency === 'BCH' ? sats : (sats * usdPrice).toFixed(2)

    this.handleValueChange(betAmount)
  }

  handleSlideChange(value) {
    const { currency } = this.state
    const { usdPrice } = this.props
    const amount = this.logSlider(Number(value[0]))
    const sats = (amount / 100000000).toFixed(8)
    const betAmount = currency === 'BCH' ? sats : (sats * usdPrice).toFixed(2)

    this.handleValueChange(betAmount, true)
  }

  handleButtonClick(inputValue) {
    const { betInfoData, usdPrice } = this.props
    const { currency } = this.state
    const { min, max } = betInfoData
    let value =
      currency === 'BCH' ? inputValue : (inputValue / usdPrice).toFixed(8)

    if (value > max) {
      value = max
    } else if (value < min) {
      value = min
    }

    value = currency === 'BCH' ? value : (value * usdPrice).toFixed(2)

    this.handleValueChange(value, true)
  }

  logData() {
    const { betInfoData } = this.props
    const { min, max } = betInfoData
    const minSats = min * 100000000
    const maxSats = max * 100000000
    const minp = 1
    const maxp = 100
    const minv = Math.log(minSats)
    const maxv = Math.log(maxSats)
    const scale = (maxv - minv) / (maxp - minp)
    return { scale, minv, minp }
  }

  logSlider(position) {
    const logData = this.logData()
    return Math.exp(logData.minv + logData.scale * (position - logData.minp))
  }

  logPosition(value) {
    const logData = this.logData()
    return (Math.log(value) - logData.minv) / logData.scale + logData.minp
  }

  render() {
    const { currency, inputValue } = this.state
    const { intl, usdPrice, betInfoData, selectedValue } = this.props
    const { min, max } = betInfoData
    const { messages } = intl

    const valueBCH = Number(selectedValue).toFixed(8)
    const valueFiat = (selectedValue * usdPrice).toFixed(2)
    const valueLabel =
      currency === 'BCH'
        ? `${numeral(valueFiat).format('$0,0.00')} USD`
        : `${parseFloat(valueBCH)} ${messages.bch}`

    const inputStep = currency === 'BCH' ? valueBCH : valueFiat

    return (
      <Wrapper>
        <H1 thin>{messages.Betinfo.selectbet}</H1>
        <InputContainer>
          <Input
            type="number"
            value={inputValue}
            step={inputStep}
            onChange={this.handleInputChange}
            onBlur={this.handleInputBlur}
          />
          <Switch>
            <InputCheck
              type="checkbox"
              value={this.state.currency === 'BCH'}
              onChange={this.handleChangeCurrency}
            />
            <SwitchSlider />
          </Switch>
        </InputContainer>
        {valueFiat !== 'NaN' && <Text>{valueLabel}</Text>}

        <SliderContainer>
          <Slider
            animate
            animationDuration={300}
            range={{
              min: [1],
              max: [100],
            }}
            start={[1]}
            connect={[true, false]}
            behaviour="tap-drag"
            onSlide={this.handleSlide}
            onChange={this.handleSlideChange}
            ref={ref => (this.NoUiSlider = ref)}
          />
        </SliderContainer>
        <ButtonContainer>
          <Button
            onClick={() => {
              this.handleValueChange(
                currency === 'BCH' ? min : (min * usdPrice).toFixed(2),
                true
              )
              this.handleBadger(betInfoData.cashaddr, min * 100000000)
            }}
          >
            Min
          </Button>
          <Button onClick={() => this.handleButtonClick(inputValue / 2, true)}>
            1/2
          </Button>
          <Button onClick={() => this.handleButtonClick(inputValue * 2, true)}>
            x2
          </Button>
          <Button
            onClick={() => {
              this.handleValueChange(
                currency === 'BCH' ? max : (max * usdPrice).toFixed(2),
                true
              )
              this.handleBadger(betInfoData.cashaddr, max * 100000000)
            }}
          >
            Max
          </Button>
        </ButtonContainer>
        <BetChances
          maxClickFn={() => {
            this.handleValueChange(
              currency === 'BCH' ? max : (max * usdPrice).toFixed(2),
              true
            )
            this.handleBadger(betInfoData.cashaddr, max * 100000000)
          }}
          minClickFn={() => {
            this.handleValueChange(
              currency === 'BCH' ? min : (min * usdPrice).toFixed(2),
              true
            )
            this.handleBadger(betInfoData.cashaddr, min * 100000000)
          }}
        />
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const selectedValue = getSelectedValue(state)
  const { USD } = getPrices(state)
  const betInfoData = getSelectedGame(state)

  return {
    selectedValue,
    usdPrice: USD,
    betInfoData,
  }
}

const mapDispatchToProps = {
  setSelectedValue,
  addTransactionId,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ValueSelect))

import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const Wrapper = styled.div`
  position: relative;
  text-align: center;
  display: flex;
  flex: 1;
  flex-basis: 25%;
  flex-direction: column;
  padding-bottom: 10px;
  canvas {
    padding: 10px;
    background: ${props => props.theme.background};
    display: inline-block;
    margin: 0 auto;
  }
`

export const QRWrap = styled.div`
  position: relative;
`

export const CopyNotify = styled.div`
  ${props =>
    props.show
      ? css`
          visibility: 'visible';
          opacity: 1;
        `
      : css`
          visibility: 'hidden';
          opacity: 0;
        `};

  position: absolute;
  transition: all 0.3s ease-out;
  padding: 10px;
  border-radius: 5px;
  background: ${props => props.theme.tertiary};
  width: 200px;
  height: 20px;
  display: flex;
  align-content: center;
  top: 115px;
  left: 50%;
  margin-left: -110px;
`

export const CopyButton = styled.button`
  border: none;
  cursor: pointer;
  background-color: ${props => props.theme.foreground};
  color: ${props => props.theme.background};
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 1px 3px ${props => props.theme.shadow};
  &:hover {
    box-shadow: none;
    background-color: ${props => props.theme.secondary};
  }
`

export const OpenWalletButton = styled(CopyButton)`
  display: inline-block;
  text-decoration: none;
  margin-top: 0;
  ${media.large`
      display: none;
  `};
`

export const CashAddr = styled.div`
  margin: 5px 10px;
  word-break: break-all;
`

import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import Container from 'atoms/Container'
import { Link } from 'gatsby'
export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  background: ${props => props.theme.foreground};
  align-items: center;
  flex-wrap: wrap;
`

export const MenuContainer = styled(Container)`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex: 1;
  position: relative;
  ${media.medium`
  justify-content: space-evenly;
`}
`

export const MainMenu = styled.div`
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  display: flex;
  ${props =>
    props.show
      ? css`
          transition: all 0.3s ease-out;
          max-height: 500px;
          visibility: visible;
        `
      : css`
          transition: all 0.3s ease-out;
          max-height: 0px;
          visibility: hidden;
        `}

  ${media.large`
      visibility: visible;
      max-height: 500px;
      top: 0;
      max-height: 100px;
      justify-content: center;
      flex-direction: row;
      flex: 1;
      transition: none;
  `}
`

export const MobileMenu = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;

  ${media.large`
      display: none;
  `}
`

export const MenuItems = styled.div`
  order: 1;
  display: flex;
  text-align: center;
  flex-direction: column;

  ${props =>
    props.show
      ? css`
          transition: all 0.3s ease-out;
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}

  ${media.large`
      order: 3;
      width: unset;
      flex-direction: row;
      visibility: visible;
      opacity: 1;
  `}
`

export const StyledLink = styled(Link)`
  color: ${props => props.theme.background};
  text-decoration: none;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  transition: color 250ms ease-in-out;
  align-self: center;
  &:hover {
    color: ${props => props.theme.tertiary};
  }
`

export const SoundLink = styled(StyledLink)`
  margin: 0;
`

export const Logo = styled.img`
  padding: 10px;
  max-height: 40px;

  ${media.medium`
  max-height: 60px;
`}
`

export const SoundButton = styled.div`
  order: 2;
  color: ${props => props.theme.background};
  display: flex;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  align-self: center;
  ${props =>
    props.show
      ? css`
          transition: all 0.3s ease-out;
          opacity: 1;
          visibility: visible;
        `
      : css`
          transition: all 0.3s ease-out;
          opacity: 0;
          visibility: hidden;
        `}
  ${media.large`
      transition: none !important;
      padding-left: 0;
      order: 2;
      opacity: 1;
      visibility: visible;
  `}
`

export const TopMenu = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.large`
  width: 100%;
  justify-content: space-between;
`}
`

export const OtherGames = styled('div')`
  ${props =>
    props.top
      ? css`
          display: none;
          ${media.large`
    display: flex;
  `}
        `
      : css`
          flex-direction: column;
          display: flex;
          margin-top: 10px;
          ${media.large`
      display: none;
  `}
        `}
`

import React from 'react'

import { injectIntl } from 'react-intl'

import Container from 'atoms/Container'
import Hero from 'components/Hero'
import Text from 'atoms/Text'
import Loading from 'atoms/Loading'

import { IconCancel, IconCheck } from 'atoms/Icons'
import { getExplorerUrl } from 'data/api'

import helpers from 'helpers'

import { formatBCHValue } from 'styles/utils'
import { Row, Col, Link, Wrapper, Title } from './styled'

class FairContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fair: null,
      fairLoaded: false,
    }
  }

  componentDidMount() {
    const { id } = this.props
    if (id)
      helpers.proveFair(id).then(result => {
        this.setState(prevState => {
          return {
            ...prevState,
            fairLoaded: true,
            fair: result,
          }
        })
      })
  }

  render() {
    const { fairGames, id, verifiedGame, intl } = this.props
    const { fair, fairLoaded } = this.state
    const { messages } = intl
    const gameLoaded = verifiedGame && Number(id) === verifiedGame.id

    const titleContent = messages.Fair['title-content']
    const loading =
      (id && !gameLoaded) || (!id && fairGames && fairGames.length < 1)

    let winResultText = ''

    if (verifiedGame.win) {
      if (!verifiedGame.broadcasted) {
        winResultText = verifiedGame.win
          ? messages.Winlist.win + ', ' + messages.Winlist.pendingTransaction
          : messages.Winlist.lose + ', ' + messages.Winlist.pendingTransaction
      } else {
        winResultText = messages.Winlist.win
      }
    } else {
      if (verifiedGame.roll === -1) {
        winResultText = messages.Winlist.invalid
      } else {
        winResultText = messages.Winlist.lose
      }
    }

    console.log(verifiedGame)
    return (
      <Wrapper>
        <Hero small>
          <Title thin>{messages.Fair.title}</Title>
          {titleContent.map((item, idx) => (
            <Text key={idx}>{item}</Text>
          ))}
        </Hero>

        {loading && <Loading />}

        {!id && fairGames.length > 0 && (
          <Container>
            <Row fairgame>
              <Col area="date">{messages.Fair.usedate}</Col>
              <Col area="seedhash">{messages.Fair.seedhash}</Col>
              <Col area="seedtext">{messages.Fair.seedtext}</Col>
            </Row>

            {!id &&
              fairGames.map((game, idx) => (
                <Row key={idx} fairgame>
                  <Col area="date">{game.useDate}</Col>
                  <Col area="seedhash">
                    <Link
                      href={getExplorerUrl(game.timestampTx)}
                      target="_blank"
                    >
                      {game.serverSeedHash}
                    </Link>
                  </Col>
                  <Col area="seedtext">
                    {game.serverSeedPlaintext || '----'}
                  </Col>
                </Row>
              ))}
          </Container>
        )}

        {gameLoaded && (
          <Container>
            <Row>
              <Col>{messages.Fair.gameid}:</Col>
              <Col>{verifiedGame.id}</Col>
            </Row>
            <Row>
              <Col>{messages.Fair.deposittxid}:</Col>
              <Col>
                <Link
                  href={getExplorerUrl(verifiedGame.depositTxHash)}
                  target="_blank"
                >
                  {verifiedGame.depositTxHash}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>{messages.Fair.betnumber}:</Col>
              <Col>{verifiedGame.bet}</Col>
            </Row>
            <Row>
              <Col>{messages.Fair.randomnumberseed}:</Col>
              <Col>{verifiedGame.randomSeed}</Col>
            </Row>
            <Row>
              <Col>{messages.Fair.diceroll}:</Col>
              <Col>{verifiedGame.roll === -1 ? 'N/A' : verifiedGame.roll}</Col>
            </Row>
            <Row>
              <Col>{messages.Fair.outcome}:</Col>
              <Col>
                {fairLoaded && (fair ? <IconCheck /> : <IconCancel />)}
                {winResultText}
              </Col>
            </Row>
            {verifiedGame.error && (
              <Row>
                <Col>{messages.Fair.error}:</Col>
                <Col>{verifiedGame.error}</Col>
              </Row>
            )}
            <Row>
              <Col>{messages.Fair.payouttxid}:</Col>
              <Col>
                <Link
                  href={getExplorerUrl(verifiedGame.payoutTxHash)}
                  target="_blank"
                >
                  {verifiedGame.payoutTxHash}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>{messages.Fair.betamount}:</Col>
              <Col>
                {formatBCHValue(verifiedGame.betAmount)} {messages.bch}
              </Col>
            </Row>
            <Row>
              <Col>{messages.Fair.payoutamount}:</Col>
              <Col>
                {formatBCHValue(verifiedGame.payout)} {messages.bch}
              </Col>
            </Row>
            {verifiedGame.overmax && (
              <Row>
                <Col>{messages.Fair.overmax}:</Col>
                <Col>
                  {formatBCHValue(verifiedGame.refunded)} {messages.bch}
                </Col>
              </Row>
            )}
            <Row>
              <Col>{messages.Fair.serverseedtx}:</Col>
              <Col>
                <Link
                  href={getExplorerUrl(verifiedGame.serverSeedTx)}
                  target="_blank"
                >
                  {verifiedGame.serverSeedTx}
                </Link>
              </Col>
            </Row>
            <Row>
              <Col>{messages.Fair.serverseedhash}:</Col>
              <Col>{verifiedGame.serverSeedHash}</Col>
            </Row>
            <Row>
              <Col>{messages.Fair.seedpublishtimestamp}:</Col>
              <Col>{verifiedGame.seedPublishTimestamp}</Col>
            </Row>
          </Container>
        )}
      </Wrapper>
    )
  }
}

export default injectIntl(FairContent)

import styled, { css } from 'styled-components'
import BadgerButton from 'components/BadgerButton'
import { media } from 'styles/utils'

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  height: auto;
  padding: 10px;
  background: white;
  border-radius: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 999;
  animation: shadow 1s infinite;
  border: 1px ${props => props.theme.highlight} solid;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);

  ${props =>
    props.show &&
    css`
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    `}
`

export const StyledBadger = styled(BadgerButton)`
  text-size: 14px;
  animation: unset;
  margin: 10px auto;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: repeat(${props => props.items}, 1fr);
`

export const Address = styled.p`
  max-width: 240px;
  word-break: break-all;
  text-align: center;
`

export const Txid = styled.div``

export const GameStats = styled.div`
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
`

export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  text-align: center;
  ${props => {
    return css`
      color: ${props.theme.foreground};
      background: ${props.theme.rowBorder};
      border: 1px solid ${props.theme.foreground};
      border-radius: 5px;
    `
  }}
`

export const Value = styled.strong`
  margin-top: 5px;
  overflow: hidden;
  display: flex;
  font-size: 12px;
  align-items: baseline;
  justify-content: center;
  white-space: pre;

  ${props =>
    props.center &&
    css`
      align-items: center;
      flex-direction: column;
    `}
  ${media.medium`
      font-size: 14px;
  `}
  ${media.large`
      word-wrap: break-word;
      overflow-y: hidden;
      overflow-x: hidden;
      max-width: 500px;
  `}
  
  ${props =>
    props.win &&
    css`
      color: green;
    `}
  
  ${props =>
    props.lose &&
    css`
      color: red;
    `}

  ${props =>
    props.small &&
    css`
      font-size: 13px;
    `}
`

export const CloseButton = styled.div`
  margin-right: 0;
  cursor: pointer;
  align-self: flex-end;
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
